import * as React from 'react'

const ContactPage = () => {

  return (
    <main>
      contactPage
    </main>
  )
}
export default ContactPage